import React, { useEffect, useState } from "react";
import logo from "../images/logo.png";
import BookADate from "./BookADate";
import { getCalApi } from "@calcom/embed-react";

const Header = () => {
  // useEffect(() => {
  //   (async function () {
  //     const cal = await getCalApi();
  //     cal("ui", { "theme": "light", "styles": { "branding": { "brandColor": "#0097FE" } }, "hideEventTypeDetails": false, "layout": "month_view" });
  //   })();
  // }, [])

  const [showBookADate, setShowBookADate] = useState(false);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (document.body.scrollTop > 75 || document.documentElement.scrollTop > 75) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const handleButtonClick = () => {
    setShowBookADate(!showBookADate);
  }

  const handleCloseModal = () => {
    setShowBookADate(false)
  }

  return (
    <div>
      <button id="myBtn" style={{ display: showButton ? 'block' : 'none' }} onClick={handleButtonClick} className="small bg-primary-1 w-button">Book A Moving Van 🚚</button>

      {showBookADate && <BookADate onClose={handleCloseModal} />}
      <div className="bg-accent-1">
        <div
          data-w-id="9f46152e-d8b7-135e-31e1-3e0b19bf741a"
          data-animation="default"
          data-collapse="medium"
          data-duration="400"
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="header-wrapper w-nav"
        >
          <div className="container-default w-container">
            <div className="header-content-wrapper">
              <div className="header-left-side">
                <a
                  href="/"
                  className="header-logo-link left w-nav-brand"
                  aria-label="home"
                >
                  <img
                    src={logo}
                    loading="eager"
                    alt="Logo Secondary - Movers X Webflow Template"
                    className="header-logo"
                  />
                </a>
                {/* <nav role="navigation" className="header-nav-menu-wrapper w-nav-menu">
                  <ul role="list" className="header-nav-menu-list">
                    <li className="header-nav-list-item left">
                      <a href="/home" aria-current="page" className="header-nav-link w-nav-link w--current">
                        Home
                      </a>
                    </li>
                    <li className="header-nav-list-item left">
                      <a href="/about" className="header-nav-link w-nav-link">
                        About
                      </a>
                    </li>
                    <li className="header-nav-list-item left">
                      <a href="/blog" className="header-nav-link w-nav-link">
                        Our Stories
                      </a>
                    </li>
                    <li className="header-nav-list-item left">
                      <a href="/driver-join" className="header-nav-link w-nav-link">
                        Join
                      </a>
                    </li>
                    <li className="header-nav-list-item left show-btn---mbl">
                      <a href="/contact" className="btn-primary bg-secondary-1 width-100 w-button">
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </nav> */}
              </div>
              <div className="header-right-side">
                {/* <div className="social-media-flex-top hidden-on-tablet">
                  <a
                    href="https://facebook.com/"
                    target="_blank"
                    className="social-media-header w-inline-block"
                  >
                    <div className="social-icon-font"></div>
                  </a>
                  <a
                    href="https://twitter.com/"
                    target="_blank"
                    className="social-media-header w-inline-block"
                  >
                    <div className="social-icon-font"></div>
                  </a>
                  <a
                    href="https://www.instagram.com/"
                    target="_blank"
                    className="social-media-header w-inline-block"
                  >
                    <div className="social-icon-font"></div>
                  </a>
                  <a
                    href="https://www.linkedin.com/"
                    target="_blank"
                    className="social-media-header w-inline-block"
                  >
                    <div className="social-icon-font"></div>
                  </a>
                </div> */}
                <button
                  id="bookADate"
                  onClick={handleButtonClick}
                  // data-cal-namespace=""
                  // data-cal-link="coolmoves/3hour"
                  // data-cal-config='{"layout":"month_view"}'
                  className="btn-primary small bg-secondary-1 w-button"
                >
                  Book A Moving Van 🚚
                </button>
                
                <div
                  data-w-id="9f46152e-d8b7-135e-31e1-3e0b19bf74a7"
                  className="hamburger-menu-wrapper w-nav-button"
                  aria-label="menu"
                  role="button"
                  tabIndex="0"
                  aria-controls="w-nav-overlay-0"
                  aria-haspopup="menu"
                  aria-expanded="false"
                >
                  {/* <div
                    className="hamburger-menu-bar top bg-neutral-100"
                    style={{
                      transform: `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg), transformStyle: preserve-3d`,
                    }}
                  ></div>
                  <div
                    className="hamburger-menu-bar bottom bg-neutral-100"
                    style={{
                      transform: `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg), transformStyle: preserve-3d`,
                    }}
                  >
                  </div> */}
                  {/* <a
                    href="tel:+27814548473"
                    className="btn-primary bg-secondary-1 button-row w-button mg-top-32px"
                  >
                    Find us on +27 81 454 8473
                  </a> */}
                  <button>

                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="w-nav-overlay"
            data-wf-ignore=""
            id="w-nav-overlay-0"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Header;
