import React from "react";
import bannerImg from "../images/original.gif"

const Banner = () => {
  return (
    <div>
      <section className="section hero v1">
        <div className="container-default w-container">
          <div className="z-index-1">
            <div className="w-layout-grid grid-2-columns primary-hero-grid">
              <div
                id="w-node-_9ebe418f-f4f3-9995-5dcb-552fbee99d20-7fbaa589"
                className="home-hero-article"
              >
                <div className="inner-container _638px">
                  <div className="inner-container _600px---tablet center">
                    <div className="inner-container _450px---mbl center">
                      <div className="inner-container _400px---mbp center">
                        <h1
                          data-w-id="fa197cbe-4699-330e-cf7a-c5e01b8b8a84"
                          className="display-1 color-neutral-100"
                        >
                          Make your next cool move!
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mg-bottom-40px">
                  <div className="inner-container _652px _100-tablet">
                    <p
                      data-w-id="ad141e70-3519-8cc8-935d-b90033ba3169"
                      className="mg-bottom-0 color-neutral-400"
                    >
                      Elevate your relocation game with our professional courier
                      and moving solutions, tailored to make your transition
                      stress-free and efficient.
                    </p>
                  </div>
                </div>
                {/* <div
                  data-w-id="60b1bb28-8068-0b1d-1d99-d5e120da7d99"
                  className="buttons-row center---tablet"
                >
                  <a href="/contact" className="btn-primary bg-secondary-1 button-row w-button">
                    Get a quote <span className="line-rounded-icon"></span>
                  </a>
                  <a
                    href="#"
                    data-w-id="f6af6a1d-9078-5077-91b1-d4b2519851a3"
                    className="play-video-wrapper w-inline-block w-lightbox"
                    aria-label="open lightbox"
                    aria-haspopup="dialog"
                  >
                    <img
                      src="https://assets-global.website-files.com/637251dd2659f1be53baa587/6373bb965fc0b29aa73c8acc_play-button-movers-x.svg"
                      loading="eager"
                      alt=""
                      className="play-video-icon"
                    />
                    <div className="mg-left-10px">
                      <div className="text-200 medium color-neutral-100">Watch our video</div>
                    </div>
                  </a>
                </div> */}
              </div>
              <img
                src={bannerImg}
                loading="eager"
                alt="We Make Moving Fast And Easy - Movers X Webflow Template"
                className="primary-hero-image-wrapper"
              />
            </div>
          </div>
          <div className="position-absolute hero-v1-bg">
            <div className="position-absolute rectangle-bg v1"></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Banner;
