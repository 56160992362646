import React, { useEffect } from 'react'
import Banner from '../components/Banner'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import { getCalApi } from "@calcom/embed-react";

const Home = () => {
  // useEffect(()=>{
	//   (async function () {
	// 	const cal = await getCalApi();
	// 	cal("floatingButton", {"calLink":"coolmoves/3hour","buttonColor":"#0097FE","buttonTextColor":"#fff","buttonText":"Book A Moving Van 📅"});
	// 	cal("ui", {"theme":"light","styles":{"branding":{"brandColor":"#0097FE"}},"hideEventTypeDetails":false,"layout":"month_view"});
	//   })();
	// }, [])

  return (
    <div>
      <Banner/>
      <Contact/>
    </div>
  )
}

export default Home