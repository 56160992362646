import logo from './logo.svg';
import './App.css';
import Layout from "./components/Layout";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import DriverJoin from './pages/DriverJoin';

function App() {
  return (
    <div className="App">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/driver-join" element={<DriverJoin />} />
          {/* <Route path="/signup" element={<SignUp />} />
          <Route path="/signin" element={<SignIn />} /> */}
        </Route>
      </Routes>


    </BrowserRouter>
  </div>
  );
}

export default App;
