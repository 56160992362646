import React from 'react'
import world from '../images/world.gif'

const Contact = () => {
    return (
        <div>
            <section className="section-banner overflow-hidden">
                <div
                    data-w-id="b318dddc-2501-7829-f537-4e4fba9438f2"
                    className="container-default w-container"
                >
                    <div className="text-center mg-bottom-24px mg-top-40px">
                        <h2 className="display-4">
                            Our Offering
                        </h2>
                    </div>
                    <div className="inner-container _350px---mbp center">
                        <div
                            data-delay="4000"
                            data-animation="cross"
                            className="slider-wrapper w-slider"
                            data-autoplay="false"
                            data-easing="ease"
                            data-hide-arrows="false"
                            data-disable-swipe="false"
                            data-autoplay-limit="0"
                            data-nav-spacing="9"
                            data-duration="500"
                            data-infinite="true"
                            role="region"
                            aria-label="carousel"
                        >
                            <div className="slider-mask w-slider-mask">
                                {/* Slide 1 */}
                                <div className="w-slide">
                                    <div className="w-layout-grid grid-3-columns logo-strip">
                                        <div className="service-card-content"><div style={{backgroundColor:`hsla(216.61016949152545, 62.11%, 18.63%, 1.00)`}} className="rounded-icon---bg"><img src="https://assets-global.website-files.com/637251dd2659f16608baa5b8/63bdfa833e0879a4d83abcad_icon-1-services-movers-webflow-template.svg" loading="eager" alt="Local moving" className="icon-wrapper _60px"/></div><div className="flex-shrink"><div className="inner-container _320px _100-mbp"><h3 className="display-4 mg-bottom-4px" style={{color: `rgb(18, 41, 77)`}}>Furniture Relocations</h3><p className="color-neutral-600">For those moving to their new home!</p></div></div></div>

                                        <div className="service-card-content"><div style={{backgroundColor:`hsla(43.10679611650486, 100.00%, 59.61%, 1.00)`}} className="rounded-icon---bg"><img src="https://assets-global.website-files.com/637251dd2659f16608baa5b8/63bdfa67044ddcd0030551e9_icon-4-services-movers-webflow-template.svg" loading="eager" alt="Local moving" className="icon-wrapper _60px"/></div><div className="flex-shrink"><div className="inner-container _320px _100-mbp"><h3 className="display-4 mg-bottom-4px" style={{color: `rgb(18, 41, 77)`}}>Business delivery</h3><p className="color-neutral-600">Deliver to your customers within 24 hours!</p></div></div></div>

                                        <div className="service-card-content"><div style={{backgroundColor:`hsla(212.4087591240876, 100.00%, 73.14%, 1.00)`}} className="rounded-icon---bg"><img src="https://assets-global.website-files.com/637251dd2659f16608baa5b8/63bdfa7af1dcf7a31c5a4e3f_icon-2-services-movers-webflow-template.svg" loading="eager" alt="Local moving" className="icon-wrapper _60px"/></div><div className="flex-shrink"><div className="inner-container _320px _100-mbp"><h3 className="display-4 mg-bottom-4px" style={{color: `rgb(18, 41, 77)`}}>Centralized Storage</h3><p className="color-neutral-600">Store your possessions securely</p></div></div></div>

                                        {/* <div className="logo-strip-wrapper">
                                            <img
                                                src="https://assets-global.website-files.com/637251dd2659f1be53baa587/6554cbe49fcae42bd7b9e2fe_company-logo-movers-x-webflow-template.svg"
                                                loading="eager"
                                                alt="Company Logo 1"
                                            />
                                        </div> */}
                                        {/* Add more logo-strip-wrapper elements for additional logos on slide 1 */}
                                    </div>
                                </div>
                            </div>
                            {/* Navigation and arrows go here */}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contact