import React, { useEffect } from 'react'
import logo from "../images/logo.png";
import { getCalApi } from "@calcom/embed-react";
import RoadBlock from './RoadBlock';
import { gsap, Power2, Elastic, Sine, Linear } from "gsap";

const Footer = () => {
  // useEffect(() => {
  //   (async function () {
  //     const cal = await getCalApi();
  //     cal("ui", { "theme": "light", "styles": { "branding": { "brandColor": "#0097FE" } }, "hideEventTypeDetails": false, "layout": "month_view" });
  //   })();
  // }, [])

  // useEffect(() => {
  //   const xmlns = "http://www.w3.org/2000/svg";
  //   const xlinkns = "http://www.w3.org/1999/xlink";

  //   const select = (s) => document.querySelector(s);
  //   const selectAll = (s) => document.querySelectorAll(s);

  //   const truckFlip = select("#truckFlip");
  //   const roadBlock = select("#roadBlock");

  //   gsap.set("svg", {
  //     visibility: "visible",
  //   });

  //   gsap.set("#truckSquash", {
  //     transformOrigin: "100% 130%",
  //   });

  //   const mainTl = gsap.timeline();

  //   const roadSideTl = gsap.timeline({
  //     repeat: -1,
  //   });
  //   roadSideTl.to("#roadMarkingSide", 0.5, {
  //     x: 0.5,
  //     y: -51,
  //     ease: Linear.easeNone,
  //   });

  //   const roadTopTl = gsap.timeline({});
  //   roadTopTl.to("#roadMarkingTop", 0.5, {
  //     x: 68,
  //     y: -26,
  //     repeat: -1,
  //     ease: Linear.easeNone,
  //   });

  //   const truckTl = gsap.timeline({
  //     repeat: -1,
  //   });
  //   truckTl.to("#truck", 4, {
  //     x: "+=30",
  //     y: "+=25",
  //     ease: Power2.easeInOut,
  //   }).to(
  //     "#truck",
  //     4,
  //     {
  //       x: "-=30",
  //       y: "-=25",
  //       delay: 1,
  //       ease: Power2.easeInOut,
  //     },
  //     "+=0.5"
  //   );

  //   const squashTl = gsap.timeline({
  //     repeat: -1,
  //   }).timeScale(3);
  //   squashTl.to("#truckSquash", 1, {
  //     scaleY: 0.99,
  //     y: 1,
  //     scaleX: 1.01,
  //     ease: Sine.easeIn,
  //   }).to("#truckSquash", 1, {
  //     scale: 1,
  //     y: 0,
  //     ease: Sine.easeOut,
  //   });

  //   mainTl.add(truckTl, 0);
  //   mainTl.add(squashTl, 0);
  //   mainTl.add(roadSideTl, 0.7);
  //   mainTl.add(roadTopTl, 0);

  //   mainTl.timeScale(2);

  //   const hitHandler = (e) => {
  //     if (truckFlip._gsTransform.rotation !== 0) {
  //       gsap.to([truckFlip, "#wholeThing"], 2, {
  //         rotation: 0,
  //         x: 0,
  //         y: 0,
  //         ease: Elastic.easeOut.config(0.6, 0.72),
  //       });
  //       gsap.fromTo(
  //         roadTopTl,
  //         0.25,
  //         {
  //           time: 0,
  //         },
  //         {
  //           time: 0.5,
  //           repeat: -1,
  //           ease: Linear.easeNone,
  //         }
  //       );
  //       gsap.fromTo(
  //         roadSideTl,
  //         0.25,
  //         {
  //           time: 0,
  //         },
  //         {
  //           time: 0.5,
  //           repeat: -1,
  //           ease: Linear.easeNone,
  //         }
  //       );
  //     } else {
  //       gsap.to(truckFlip, 2, {
  //         rotation: 180,
  //         x: -20,
  //         y: -50,
  //         ease: Elastic.easeOut.config(0.6, 0.72),
  //       });
  //       gsap.to("#wholeThing", 2, {
  //         rotation: -180,
  //         transformOrigin: "50% 50%",
  //         ease: Elastic.easeOut.config(0.6, 0.72),
  //       });
  //       gsap.fromTo(
  //         roadTopTl,
  //         0.25,
  //         {
  //           time: 0.5,
  //         },
  //         {
  //           time: 0,
  //           repeat: -1,
  //           ease: Linear.easeNone,
  //         }
  //       );
  //     }
  //     gsap.fromTo(
  //       roadSideTl,
  //       0.25,
  //       {
  //         time: 0.5,
  //       },
  //       {
  //         time: 0,
  //         repeat: -1,
  //         ease: Linear.easeNone,
  //       }
  //     );
  //   };

  //   select("#hit").addEventListener("click", hitHandler);

  //   gsap.set(truckFlip, {
  //     rotation: 0,
  //     transformOrigin: "50% 50%",
  //     x: 0,
  //     y: 0,
  //   });

  //   const isDevice = /android|webos|iphone|ipad|ipod|blackberry/i.test(
  //     navigator.userAgent.toLowerCase()
  //   );
  //   if (!isDevice) {
  //     select("#wholeRoad").setAttribute("filter", "url(#edgeClean)");
  //     select("#truck").setAttribute("filter", "url(#edgeClean)");
  //   }

  //   return () => {
  //     select("#hit").removeEventListener("click", hitHandler);
  //   };
  // }, []);

  return (
    <footer>
      <div className="footer-container">
        <div className="container-default w-container">
          <div className="inner-container footer-content">
            <div className="inner-container footer-v1">
              <div className="footer-top">
                <div className="w-layout-grid grid-3-columns footer-top-grid">
                  <div
                  >
                    <div className="mg-bottom-40px">
                      <div className="text-300 bold color-neutral-100">
                        Main pages
                      </div>
                    </div>
                    <div className="w-layout-grid grid-2-columns main-page-grid">
                      <ul role="list" className="footer-list-wrapper">
                        <li className="footer-list-item"><a href="/home" aria-current="page" className="footer-link w--current">Home</a></li>
                        {/* <li className="footer-list-item"><a data-cal-namespace=""
                  data-cal-link="coolmoves/3hour"
                  data-cal-config='{"layout":"month_view"}' className="footer-link">Book A Moving Van 📅</a></li> */}
                        {/* <li className="footer-list-item"><a href="/blog" className="footer-link">Blog</a></li>
                        <li className="footer-list-item"><a href="https://moverstemplate.webflow.io/blog/8-expert-organizing-tips-to-make-your-move-easier" className="footer-link">Blog post</a></li>
                        <li className="footer-list-item"><a href="/services" className="footer-link">Services</a></li>
                        <li className="footer-list-item"><a href="https://moverstemplate.webflow.io/service/local-moving" className="footer-link">Single service</a></li> */}
                      </ul>

                      <ul role="list" className="footer-list-wrapper"><li className="footer-list-item"><a href="tel:+27659962011" className="footer-link">Contact us at <br/> 065-996-2011 📞</a>
                      </li>
                      <li className="footer-list-item"><a href="/driver-join" className="footer-link">We are hiring 🗄️</a></li>
                      {/* <li className="footer-list-item"><a href="https://moverstemplate.webflow.io/product/medium-size" className="footer-link">Single package</a></li><li className="footer-list-item"><a href="/coming-soon" className="footer-link">Coming soon</a></li><li className="footer-list-item"><a href="https://brixtemplates.com/more-webflow-templates" target="_blank" className="footer-link more-webflow-link">More Webflow Templates</a></li> */}
                      </ul>
                    </div>
                  </div>
                  <div
                  >
                    {/* <div className="mg-bottom-40px">
                      <div className="text-300 bold color-neutral-100">
                        Utility pages
                      </div>
                    </div>
                    <div className="w-layout-grid grid-1-column">
                      <ul role="list" className="footer-list-wrapper"><li className="footer-list-item"><a href="/utility-pages/style-guide" className="footer-link">Style guide</a></li><li className="footer-list-item"><a href="https://moverstemplate.webflow.io/404" className="footer-link">404 Not found</a></li><li className="footer-list-item"><a href="https://moverstemplate.webflow.io/401" className="footer-link">Password protected</a></li><li className="footer-list-item"><a href="/utility-pages/start-here" className="footer-link">Start here</a></li><li className="footer-list-item"><a href="/utility-pages/licenses" className="footer-link">Licenses</a></li><li className="footer-list-item"><a href="/utility-pages/changelog" className="footer-link">Changelog</a></li></ul>
                    </div> */}
                  </div>
                  
                  {/* <div
                    className="footer-newsletter-card"
                  >
                  <RoadBlock/>
                     <div className="z-index-1"><div className="inner-container newsletter-title"><div className="mg-bottom-16px"><div className="text-300 bold color-neutral-100">Let us reach out to you 📞</div></div><p className="color-neutral-200 mg-bottom-24px">Send us your email address</p></div><div className="form-button-inside-block w-form"><form id="wf-form-Footer-Subscribe" name="wf-form-Footer-Subscribe" data-name="Footer Subscribe" method="get" data-wf-page-id="637251dd2659f14d7fbaa589" data-wf-element-id="2fe2ab4c-9247-8c0c-a740-f85f6657f395" aria-label="Footer Subscribe"><div className="position-relative"><input type="text" className="input button-inside w-input" maxLength="256" name="Footer-Email-2" data-name="Footer Email 2" placeholder="Enter your email" id="Footer-Email-2" required="" /><input type="submit" value="Cool, send!" data-wait="Please wait..." id="w-node-_2fe2ab4c-9247-8c0c-a740-f85f6657f398-91dd5701" className="btn-primary inside-input default w-button" /></div></form><div className="success-message alt-subscribe-success w-form-done" tabIndex="-1" role="region" aria-label="Footer Subscribe success"><div className="text-left"><div className="flex-horizontal"><div className="check-circle left small"><div className="line-rounded-icon"></div></div><div>Thanks for joining our newsletter.</div></div></div></div><div className="error-message w-form-fail" tabIndex="-1" role="region" aria-label="Footer Subscribe failure"><div>Oops! Something went wrong.</div></div></div></div>
                    <img src="https://assets-global.website-files.com/637251dd2659f1be53baa587/6374f519dfa0813f780e2374_subscribe-to-our-newsletter-movers-x.svg" loading="eager" alt="Subscribe To Our Newsletter - Movers X Webflow Template" className="floating-item newsletter-card-bottom-right"></img> 
                  </div> */}
                </div>
              </div>
            </div>
            <div className="footer-bottom">
              <div className="footer-bottom-flex">
                <a href="/" className="footer-logo-wrapper mg-bottom-0 w-inline-block">
                  <img
                    src={logo}
                    alt="Logo Secondary - Movers X Webflow Template"
                    className="footer-logo"
                  />
                </a>
                <div className="social-media-flex-bottom">
                  {/* Your social media icons go here */}
                </div>
              </div>
            </div>
            <div className="footer-bg-v1"></div>
          </div>
        </div>
        <div className="footer-bg-secondary-1"></div>
      </div>
    </footer>
  )
}

export default Footer