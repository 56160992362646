import React, { useState, useEffect } from 'react'
import { registerDriver } from '../actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const DriverJoin = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [vehicleSize, setVehicleSize] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [location, setLocation] = useState("");

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const nav = useNavigate();

  const dispatch = useDispatch();

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo } = userRegister;

  useEffect(() => {
    if (userInfo) {
      nav("/");
    }
  }, [userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    setIsFormSubmitted(true)
    dispatch(registerDriver(fullName, email, phoneNumber, idNumber, vehicleSize, vehicleType, location));
  };

  return (
    <div>
      {/* <section className="section hero v6">
        <div className="container-default w-container">
          <div className="z-index-1">
            <div className="mg-bottom-64px">
              <div className="flex-horizontal text-center">
                <div className="inner-container _620px">
                  <div className="inner-container _550px---mbl center">
                    <div
                      data-w-id="14db7c3d-e232-6e3b-e629-b032d7fb2ad5"
                      style={{
                        transform:
                          "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        opacity: 1,
                        transformStyle: "preserve-3d",
                      }}
                      className="subtitle mg-bottom-18px"
                    >
                      Get in touch
                    </div>
                    <div className="inner-container contact-title-wrapper">
                      <h1
                        data-w-id="14db7c3d-e232-6e3b-e629-b032d7fb2ad8"
                        style={{
                          transform:
                            "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                          opacity: 1,
                          transformStyle: "preserve-3d",
                        }}
                        className="display-1 color-neutral-100"
                      >
                        Contact <span className="text-no-wrap">us today</span>
                      </h1>
                      <img
                        src="https://assets-global.website-files.com/637251dd2659f1be53baa587/63765a368d8e24207d3f68cd_contact-us-today-top-left-movers-x.svg"
                        loading="eager"
                        style={{ opacity: 1 }}
                        data-w-id="14db7c3d-e232-6e3b-e629-b032d7fb2ada"
                        alt=""
                        className="floating-item contact-top-left"
                      />
                      <img
                        src="https://assets-global.website-files.com/637251dd2659f1be53baa587/63765a36ef9dc40c8f0a6bff_contact-us-today-bottom-right-movers-x.svg"
                        loading="eager"
                        style={{ opacity: 1 }}
                        data-w-id="14db7c3d-e232-6e3b-e629-b032d7fb2adb"
                        alt=""
                        className="floating-item contact-bottom-right"
                      />
                    </div>
                    <p
                      data-w-id="14db7c3d-e232-6e3b-e629-b032d7fb2adc"
                      style={{
                        transform:
                          "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        opacity: 1,
                        transformStyle: "preserve-3d",
                      }}
                      className="mg-bottom-0 color-neutral-400"
                    >
                      Sit velit varius quis risus pellentesque eget urna
                      condimentum et justo sollicitudin aliquet lorem
                      pellentesque urna quam eu
                      <span className="text-no-wrap">sed id non lacus.</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-layout-grid grid-2-columns">
              <div
                id="w-node-_14db7c3d-e232-6e3b-e629-b032d7fb2adf-efa4df7c"
                data-w-id="14db7c3d-e232-6e3b-e629-b032d7fb2adf"
                style={{
                  transform:
                    "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="card contact-card v2"
              >
                <div>
                  <img
                    src="https://assets-global.website-files.com/637251dd2659f1be53baa587/63752ce2d0fd9147cd63c063_send-us-an-email-movers-x.svg"
                    loading="eager"
                    alt="Send Us An Email - Movers X Webflow Template"
                    className="icon-wrapper _65px"
                  />
                </div>
                <div className="flex-shrink">
                  <div className="inner-container contact-cart-article">
                    <h2 className="display-4 mg-bottom-10px">
                      Send us <span className="text-no-wrap">an email</span>
                    </h2>
                    <p className="mg-bottom-24px">
                      Amet, tellus condimentum cras massa dui cursus nisl neque
                      dui risus id iaculis euismod
                      <span className="text-no-wrap">massa aliquam.</span>
                    </p>
                    <div className="inner-container _333px center _100---mbp">
                      <a
                        href="mailto:info@movers.com"
                        className="btn-primary width-100 w-button"
                      >
                        info@movers.com
                        <span className="line-rounded-icon"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                id="w-node-_14db7c3d-e232-6e3b-e629-b032d7fb2aed-efa4df7c"
                data-w-id="14db7c3d-e232-6e3b-e629-b032d7fb2aed"
                style={{
                  transform:
                    "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d",
                }}
                className="card contact-card v2"
              >
                <div>
                  <img
                    src="https://assets-global.website-files.com/637251dd2659f1be53baa587/63752ce2a425a055dd323902_give-us-a-call-movers-x.svg"
                    loading="eager"
                    alt="Give Us A Call - Movers X Webflow Template"
                    className="icon-wrapper _65px"
                  />
                </div>
                <div className="flex-shrink">
                  <div className="inner-container contact-cart-article">
                    <h2 className="display-4 mg-bottom-10px">
                      Give us <span className="text-no-wrap">a call</span>
                    </h2>
                    <p className="mg-bottom-24px">
                      Amet, tellus condimentum cras massa dui cursus nisl neque
                      dui risus id iaculis euismod
                      <span className="text-no-wrap">massa aliquam.</span>
                    </p>
                    <div className="inner-container _333px center _100---mbp">
                      <a
                        href="tel:(414)946-4530"
                        className="btn-primary width-100 w-button"
                      >
                        (414) 946 - 4530
                        <span className="line-rounded-icon"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute hero-v6-bg">
          <div className="position-absolute rectangle-bg"></div>
        </div>
      </section> */}

      <section className="section top-120px overflow-hidden">
        <div className="container-default w-container">
          <div className="mg-bottom-64px mg-bottom-60px---mbp">
            <div className="text-center---tablet">
              <div className="inner-container _700px---tablet center">
                <div className="inner-container _550px---mbl center">
                  <div className="w-layout-grid grid-2-columns title-and-paragraph">
                    <div className="flex-vertical">
                      <div className="inner-container _610px center---tablet">
                        <div
                          data-w-id="38c864c9-1344-e5f2-562d-bb298242b1db"
                          style={{
                            transform: `translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
                            opacity: `1`,
                            transformStyle: `preserve-3d`,
                          }}
                          className="subtitle mg-bottom-18px"
                        >
                          We Are Hiring
                        </div>
                        <h2
                          data-w-id="38c864c9-1344-e5f2-562d-bb298242b1de"
                          style={{
                            transform: `translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
                            opacity: `1`,
                            transformStyle: `preserve-3d`,
                          }}
                          className="display-2 mg-bottom-0"
                        >
                          <span className="bg-yellow-line v5">
                            Join Our Team
                          </span>

                        </h2>
                      </div>
                    </div>
                    <div
                      id="w-node-_38c864c9-1344-e5f2-562d-bb298242b1e2-efa4df7c"
                      className="inner-container _430px _100-tablet"
                    >
                      <p
                        data-w-id="38c864c9-1344-e5f2-562d-bb298242b1e3"
                        style={{
                          transform: `translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
                          opacity: `1`,
                          transformStyle: `preserve-3d`,
                        }}
                        className="mg-bottom-0"
                      >
                        Fulfill client orders from our platform
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-layout-grid grid-2-columns contact-form-grid">
            <div
              id="w-node-cd629c06-0cf0-3db7-1d1f-769fc0af77f5-efa4df7c"
              className="contact-form-wrapper"
            >
              <div
                data-w-id="cd629c06-0cf0-3db7-1d1f-769fc0af77f6"
                style={{
                  transform: `translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
                  opacity: `1`,
                  transformStyle: `preserve-3d`,
                }}
                className="card contact-form-card"
              >
                {isFormSubmitted ?
                  (<>
                    <div className="contact-form-block w-form"><div className="success-message w-form-done" tabindex="-1" role="region" aria-label="Contact Form success" style={{display: `block`}}><div className="text-center"><div className="check-circle center"><div className="line-rounded-icon"></div></div><div className="heading-h4-size">Your message has been submitted.<br />Thanks for your submission</div></div></div><div className="error-message w-form-fail" tabindex="-1" role="region" aria-label="Contact Form failure" style={{ display: `none` }}><div>Oops! Something went wrong.</div></div></div>
                  </>) :
                  (<>
                    <div className="contact-form-block w-form">
                      <form
                        id="wf-form-Contact-Form"
                        name="wf-form-Contact-Form"
                        data-name="Contact Form"
                        onSubmit={submitHandler}
                        method="get"
                        data-wf-page-id="637255b5389c7ab4efa4df7c"
                        data-wf-element-id="cd629c06-0cf0-3db7-1d1f-769fc0af77f8"
                        aria-label="Contact Form"
                      >
                        <div className="w-layout-grid grid-2-columns form">
                          <div>
                            <label htmlFor="name">Full name</label>
                            <input
                              type="text"
                              className="input w-input"
                              maxLength="256"
                              name="Name"
                              value={fullName}
                              onChange={(e) => setFullName(e.target.value)}
                              data-name="Name"
                              id="name"
                              required=""
                            />
                          </div>
                          <div>
                            <label htmlFor="email">Email address</label>
                            <input
                              type="email"
                              className="input w-input"
                              maxLength="256"
                              name="Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              data-name="Email"
                              id="email"
                              required=""
                            />
                          </div>
                          <div>
                            <label htmlFor="phone">Phone number</label>
                            <input
                              type="tel"
                              className="input w-input"
                              maxLength="256"
                              name="Phone"
                              value={phoneNumber}
                              onChange={(e) => setPhoneNumber(e.target.value)}
                              data-name="Phone"
                              id="phone"
                              required=""
                            />
                          </div>
                          <div>
                            <label htmlFor="IDNumber">ID Number</label>
                            <input
                              type="text"
                              className="input w-input"
                              maxLength="256"
                              name="IDNumber"
                              value={idNumber}
                              onChange={(e) => setIdNumber(e.target.value)}
                              data-name="IDNumber"
                              id="idnumber"
                              required=""
                            />
                          </div>
                          <div>
                            <label htmlFor="vehicleSize">Vehicle Size</label>
                            <input
                              type="text"
                              className="input w-input"
                              maxLength="256"
                              name="vehicleSize"
                              value={vehicleSize}
                              onChange={(e) => setVehicleSize(e.target.value)}
                              data-name="vehicleSize"
                              id="vehicleSize"
                              required=""
                            />
                          </div>
                          <div>
                            <label htmlFor="vehicleType">Vehicle Type</label>
                            <input
                              type="text"
                              className="input w-input"
                              maxLength="256"
                              name="vehicleType"
                              value={vehicleType}
                              onChange={(e) => setVehicleType(e.target.value)}
                              data-name="vehicleType"
                              id="vehicleType"
                              required=""
                            />
                          </div>
                          <div id="w-node-cd629c06-0cf0-3db7-1d1f-769fc0af780a-efa4df7c">
                            <label htmlFor="message">Location</label>
                            <textarea
                              id="Location"
                              name="Location"
                              value={location}
                              onChange={(e) => setLocation(e.target.value)}
                              maxLength="5000"
                              data-name="Location"
                              placeholder="City"
                              className="text-area w-input"
                            ></textarea>
                          </div>
                          <div
                            id="w-node-cd629c06-0cf0-3db7-1d1f-769fc0af780e-efa4df7c"
                            className="btn-primary form-btn---arrow---wrapper"
                          >
                            <input
                              type="submit"
                              value="Submit"
                              data-wait="Please wait..."
                              id="w-node-cd629c06-0cf0-3db7-1d1f-769fc0af780f-efa4df7c"
                              className="form-btn---arrow w-button"
                            />
                            <div className="line-rounded-icon"></div>
                          </div>
                        </div>
                      </form>
                      <div
                        className="success-message w-form-done"
                        tabIndex="-1"
                        role="region"
                        aria-label="Contact Form success"
                      >
                        <div className="text-center">
                          <div className="check-circle center">
                            <div className="line-rounded-icon"></div>
                          </div>
                          <div className="heading-h4-size">
                            Your message has been submitted.
                            <br />
                            We will get back to you within
                            <span className="text-no-wrap">24-48 hours.</span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="error-message w-form-fail"
                        tabIndex="-1"
                        role="region"
                        aria-label="Contact Form failure"
                      >
                        <div>Oops! Something went wrong.</div>
                      </div>
                    </div>
                  </>)
                }

              </div>
            </div>
            <div
              id="w-node-cd629c06-0cf0-3db7-1d1f-769fc0af7820-efa4df7c"
              className="contact-form-content"
            >
              <div className="position-relative z-index-1">
                <div
                  id="w-node-cd629c06-0cf0-3db7-1d1f-769fc0af7822-efa4df7c"
                  data-w-id="cd629c06-0cf0-3db7-1d1f-769fc0af7822"
                  style={{
                    transform: `translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
                    opacity: `1`,
                    transformStyle: `preserve-3d`,
                  }}
                  className="contact-card first"
                >
                  <div>
                    <img
                      src="https://assets-global.website-files.com/637251dd2659f1be53baa587/63752ce2d0fd9147cd63c063_send-us-an-email-movers-x.svg"
                      loading="eager"
                      alt="Send Us An Email - Movers X Webflow Template"
                      className="icon-wrapper _60px"
                    />
                  </div>
                  <div className="flex-shrink">
                    <div className="inner-container _600px---tablet">
                      <div className="inner-container _500px---mbl">
                        <h3 className="display-4 color-neutral-100 mg-bottom-8px">
                          Send us <span className="text-no-wrap">an email</span>
                        </h3>
                        <p className="mg-bottom-24px color-neutral-400">
                          Email Us
                        </p>
                        <a
                          href="mailto:hey@coolmoves.co.za"
                          className="link-wrapper contact-link w-inline-block"
                        >
                          <div className="link-text">hey@coolmoves.co.za</div>
                          <div className="line-rounded-icon link-icon-right">
                            
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  data-w-id="cd629c06-0cf0-3db7-1d1f-769fc0af7835"
                  style={{
                    transform: `translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
                    opacity: `1`,
                    transformStyle: `preserve-3d`,
                  }}
                  className="contact-card last"
                >
                  <div>
                    <img
                      src="https://assets-global.website-files.com/637251dd2659f1be53baa587/63752ce2a425a055dd323902_give-us-a-call-movers-x.svg"
                      loading="eager"
                      alt="Give Us A Call - Movers X Webflow Template
"
                      className="icon-wrapper _60px"
                    />
                  </div>
                  <div className="flex-shrink">
                    <div className="inner-container _600px---tablet">
                      <div className="inner-container _500px---mbl">
                        <h3 className="display-4 color-neutral-100 mg-bottom-8px text-no-wrap">
                          Give us <span className="text-no-wrap">a call</span>
                        </h3>
                        <p className="mg-bottom-24px color-neutral-400">
                          Call us
                        </p>
                        <a
                          href="tel:+27814548473"
                          className="link-wrapper contact-link w-inline-block"
                        >
                          <div className="link-text">065 - 996 - 2011</div>
                          <div className="line-rounded-icon link-icon-right">
                            
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-form-content-bg"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DriverJoin;
