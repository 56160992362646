import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../actions/userActions'
import { EVENT_CREATE_RESET } from '../constants/eventConstants'
import { useNavigate } from 'react-router-dom'
import { createEvent } from '../actions/eventActions'
import axios from 'axios'
import delay from '../utils/delay'
import toast from 'react-hot-toast'
import lvan from '../images/lvan.png'
import mvan from '../images/mvan.png'
import svan from '../images/svan.png'
import { GoogleMap, LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import LocationPicker from './LocationPicker'
import LocationSearchBox from './LocationSearchBox'

const BookADate = ({ onClose }) => {
    const nav = useNavigate();

    const [startDate, setStartDate] = useState('')
    const [startLocation, setStartLocation] = useState('')
    const [endLocation, setEndLocation] = useState('')
    const [selectedVehicle, setSelectedVehicle] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [uploading, setUploading] = useState(false)

    const inputRef = useRef();

    const autocompleteRef = useRef(null);
    const [selectedPlace, setSelectedPlace] = useState(null);

    const handlePlaceSelect = () => {
        // if (onPlaceSelect && selectedPlace) {
        // }
    };

    const dispatch = useDispatch()

    const eventDetails = useSelector((state) => state.eventDetails)
    const { loading, error, event } = eventDetails

    const eventCreate = useSelector((state) => state.eventCreate)
    const {
        loading: loadingCreate,
        error: errorCreate,
        success: successCreate,
        event: createdEvent,
    } = eventCreate

    const handleClose = () => {
        onClose(); // Call the onClose function passed from the parent component
    };

    const handleVehicleChange = (e) => {
        setSelectedVehicle(e.target.value);
    };

    useEffect(() => {
        // if (successCreate) {
        //     dispatch({ type: EVENT_CREATE_RESET })
        //     nav('/')
        // }
    }, [dispatch])

    // const uploadFileHandler = async (e) => {
    //     const file = e.target.files[0]
    //     const formData = new FormData()
    //     formData.append('image', file)
    //     setUploading(true)

    //     try {
    //         const config = {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         }

    //         const { data } = await axios.post('/api/upload', formData, config)

    //         setImage(data)
    //         setUploading(false)
    //     } catch (error) {
    //         console.error(error)
    //         setUploading(false)
    //     }
    // }

    // const handleOptionChange = (e) => {
    //     setCategory(e.target.value);
    // }

    const submitHandler = (e) => {
        e.preventDefault()
        setIsFormSubmitted(true)
        dispatch(
            createEvent({
                startDate,
                startLocation,
                endLocation,
                selectedVehicle,
                email,
                phoneNumber
            })
        )
        // if (successCreate) {
        //     async function completeRequest() {
        //         toast.success("Event successfully created!")
        //         await delay(3000)
        //         nav('/')
        //     }
        //     completeRequest()
        // }
    }

    return (
        <div>
            <div
                data-node-type="commerce-cart-container-wrapper"
                style={{ opacity: 1, transition: "opacity 300ms ease 0s" }}
                className="w-commerce-commercecartcontainerwrapper w-commerce-commercecartcontainerwrapper--cartType-modal cart-wrapper"
            >
                <div
                    data-node-type="commerce-cart-container"
                    role="dialog"
                    className="w-commerce-commercecartcontainer cart-container"
                    style={{ transform: "scale(1)", transition: "transform 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s" }}
                >
                    <div className="w-commerce-commercecartheader cart-header">
                        <h4 className="w-commerce-commercecartheading heading-h4-size">Book A Moving Van 📅</h4>
                        <a
                            onClick={handleClose}
                            data-node-type="commerce-cart-close-link"
                            className="w-commerce-commercecartcloselink cart-close-button w-inline-block"
                            role="button"
                            aria-label="Close cart"
                        >
                            <div className="line-square-icon"></div>
                        </a>
                    </div>
                    <div className="w-commerce-commercecartformwrapper cart-form-wrapper">

                        {isFormSubmitted ? (
                            <div className="contact-form-block w-form"><div className="success-message w-form-done" tabindex="-1" role="region" aria-label="Contact Form success" style={{ display: `block` }}><div className="text-center"><div className="check-circle center"><div className="line-rounded-icon"></div></div><div className="heading-h4-size">Your request has been submitted.<br /> We will now reach out to you</div></div></div></div>
                        ) : (

                            <form onSubmit={submitHandler} className="w-commerce-commercecartform">
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div>
                                                <label className="form-label">Date and Time ⏰</label>
                                            </div>
                                            <div className="d-flex">
                                                <div className="n-chk">
                                                    <div className="">
                                                        <input
                                                            className="form-control"
                                                            type="datetime-local"
                                                            onChange={(e) => setStartDate(e.target.value)}
                                                            name="event-start-date"
                                                            placeholder='yyyy/mm/dd --:--'
                                                            value={startDate}
                                                            id="modalDefault"
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-4">
                                            <div className="">
                                                <label className="form-label">Pickup location 🅰️</label>
                                                <input id="event-title" value={startLocation} onChange={(e) => setStartLocation(e.target.value)} type="text" placeholder='4 Eshowe Street, Paulshof, Sandton' className="form-control" />
                                                {/* <LocationSearchBox /> */}
                                            </div>
                                        </div>

                                        <div className="col-md-12 mt-4">
                                            <div className="">
                                                <label className="form-label">Destination 🅱️</label>
                                                <input id="event-title" value={endLocation} onChange={(e) => setEndLocation(e.target.value)} type="text" placeholder="29 Augrabies Street, Pretoria East" className="form-control" />
                                            </div>
                                        </div>

                                        <div className="col-md-12 mt-4">
                                            <div className="">
                                                <label className="form-label">Vehicle Size Required</label>

                                                <div className="row">
                                                    <div className="col-md-4 mt-4">
                                                        <input
                                                            type="radio"
                                                            id="svan"
                                                            name="vehicle"
                                                            value="svan"
                                                            checked={selectedVehicle === 'svan'}
                                                            onChange={handleVehicleChange}
                                                        />
                                                        <label htmlFor="car">Small Van</label>
                                                        <img src={svan} alt="svan" />
                                                    </div>
                                                    <div className="col-md-4 mt-4">
                                                        <input
                                                            type="radio"
                                                            id="mvan"
                                                            name="vehicle"
                                                            value="mvan"
                                                            checked={selectedVehicle === 'mvan'}
                                                            onChange={handleVehicleChange}
                                                        />
                                                        <label htmlFor="truck">Medium Van</label>
                                                        <img src={mvan} alt="mvan" />
                                                    </div>
                                                    <div className="col-md-4 mt-4">
                                                        <input
                                                            type="radio"
                                                            id="lvan"
                                                            name="vehicle"
                                                            value="lvan"
                                                            checked={selectedVehicle === 'lvan'}
                                                            onChange={handleVehicleChange}
                                                        />
                                                        <label htmlFor="motorcycle">Large Van</label>
                                                        <img src={lvan} alt="mvan" />
                                                    </div>

                                                </div>
                                                {/* <input id="event-title" value={selectedVehicle} onChange={(e) => setSelectedVehicle(e.target.value)} type="text" className="form-control" /> */}
                                            </div>
                                        </div>

                                        <div className="col-md-6 mt-4">
                                            <div className="">
                                                <label className="form-label">Email</label>
                                                <input id="user-email" value={email} onChange={(e) => setEmail(e.target.value)} type="email" placeholder='hey@coolmoves.co.za' className="form-control" />
                                            </div>
                                        </div>

                                        <div className="col-md-6 mt-4">
                                            <div className="">
                                                <label className="form-label">Phone number</label>
                                                <input id="user-number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} type="tel" placeholder='0659962011' className="form-control" />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-6 mt-4">
                                        <div className="">
                                            <label className="form-label">From</label>
                                            <input id="event-start-date" value={startDate} onChange={(e) => setStartDate(e.target.value)} type="date" className="form-control" />
                                        </div>
                                    </div> */}
                                        {/* <div className="col-md-6 mt-4">
                                        <div className="">
                                            <label className="form-label">To</label>
                                            <input id="event-end-date" value={endDate} onChange={(e) => setEndDate(e.target.value)} type="date" className="form-control" />
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    {/* <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button> */}
                                    <button type="submit" className="btn btn-primary btn-add-event" style={{ display: `block`, backgroundColor: 'green' }}>
                                        Book
                                    </button>
                                </div>
                            </form>
                        )}



                        <div className="w-commerce-commercecartemptystate empty-state cart-empty" style={{ display: "none" }}>
                            {/* This part contains dynamic content */}
                        </div>
                        <div aria-live="" style={{ display: "none" }} data-node-type="commerce-cart-error" className="w-commerce-commercecarterrorstate error-message cart-error">
                            {/* This part contains dynamic content */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookADate