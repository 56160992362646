import React, { useState } from 'react';
import { GoogleMap, LoadScript, Autocomplete } from '@react-google-maps/api';

const LocationPicker = ({ onPlaceSelect }) => {
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [selectedPlace, setSelectedPlace] = useState(null);

    const handlePlaceSelect = () => {
        if (onPlaceSelect && selectedPlace) {
            onPlaceSelect(selectedPlace);
        }
    };

    return (
        <LoadScript googleMapsApiKey="YOUR_API_KEY">
            <GoogleMap
                mapContainerStyle={{ width: '100%', height: '400px' }}
                center={center}
                zoom={15}
                onClick={(e) => {
                    setSelectedPlace({ lat: e.latLng.lat(), lng: e.latLng.lng() });
                    handlePlaceSelect();
                }}
            >
                {/* <Autocomplete
                    onLoad={(autocomplete) => {
                        console.log('Autocomplete loaded:', autocomplete);
                    }}
                    onPlaceChanged={() => {
                        const place = autocomplete.getPlace();
                        console.log(place);
                        setSelectedPlace({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
                        handlePlaceSelect();
                    }}
                >
                    <input
                        type="text"
                        placeholder="Search for a place"
                        style={{
                            boxSizing: 'border-box',
                            border: '1px solid transparent',
                            width: '240px',
                            height: '32px',
                            padding: '0 12px',
                            borderRadius: '3px',
                            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                            fontSize: '14px',
                            outline: 'none',
                            textOverflow: 'ellipses',
                            position: 'absolute',
                            left: '50%',
                            marginLeft: '-120px'
                        }}
                    />
                </Autocomplete> */}
            </GoogleMap>
        </LoadScript>
    );
};

const FormWithLocationPicker = () => {
    const [startDate, setStartDate] = useState('');
    const [startLocation, setStartLocation] = useState('');
    const [endLocation, setEndLocation] = useState('');

    const submitHandler = (e) => {
        e.preventDefault();
        // Handle form submission
    };

    const handlePlaceSelect = (place) => {
        console.log('Selected place:', place);
        // Do something with the selected place
    };

    return (
        <form onSubmit={submitHandler} className="w-commerce-commercecartform">
            <div className="modal-body">
                <div className="row">
                    <div className="col-md-12">
                        <div>
                            <label className="form-label">Date and Time</label>
                        </div>
                        <div className="d-flex">
                            <div className="n-chk">
                                <div className="">
                                    <input
                                        className="form-control"
                                        type="datetime-local"
                                        onChange={(e) => setStartDate(e.target.value)}
                                        name="event-start-date"
                                        value={startDate}
                                        id="modalDefault"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mt-4">
                        <div className="">
                            <label className="form-label">Pickup location</label>
                            <LocationPicker onPlaceSelect={handlePlaceSelect} />
                        </div>
                    </div>
                    <div className="col-md-12 mt-4">
                        <div className="">
                            <label className="form-label">Destination</label>
                            <input id="event-title" value={endLocation} onChange={(e) => setEndLocation(e.target.value)} type="text" className="form-control" />
                        </div>
                    </div>
                    {/* Vehicle selection options can go here */}
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-danger" data-bs-dismiss="modal">Close</button>
                <button type="submit" className="btn btn-primary btn-add-event" style={{ display: `block` }}>
                    Add Event
                </button>
            </div>
        </form>
    );
};

export default FormWithLocationPicker;
